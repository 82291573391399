import React, { useEffect } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/layout/layout';
import { Home } from './pages/home';
import { Limits } from './pages/limits';
import { SoftwareDevelopment } from './pages/software-development';
import { Careers } from './pages/careers';
import { AboutUs } from './pages/aboutUs';
import { Contacts } from './pages/contacts';
import { Prices } from './pages/prices';
import { Questions } from './pages/questions';
import { QuestionsSection } from './pages/questions/questionsSection';
import { useError, useLanguage } from './store/slices/common/selectors';
import { useAppDispatch } from './store';
import { commonSlice } from './store/slices/common';
// import { useLazyGetDictionaryQuery } from 'store/api/dictionary';
// import { useDictionary } from './store/slices/dictionary/selectors';
import { Terms } from './pages/terms';
import { Affiliate } from './pages/affiliate';
import { Error } from './pages/error';
import { ErrorPage } from './pages/error/errorPage';
import { useConfigData } from 'hooks/useConfigData';

function App() {
  const dispatch = useAppDispatch();
  const { data } = useConfigData();
  // const [trigger] = useLazyGetDictionaryQuery();
  const error = useError();
  // const dictionary = useDictionary();
  const lang = useLanguage();

  useEffect(() => {
    if (data) {
      const description = document.querySelector('meta[name=description]');
      if (description) {
        description.setAttribute('content', data.seo.description);
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'description');
        meta.setAttribute('content', data.seo.description);
        document.head.appendChild(meta);
      }
      const keywords = document.querySelector('meta[name=keywords]');
      if (keywords) {
        keywords.setAttribute('content', data.seo.keywords);
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'keywords');
        meta.setAttribute('content', data.seo.keywords);
        document.head.appendChild(meta);
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data && !lang) {
      dispatch(commonSlice.actions.setLanguage(data.defaultLanguage));
    }
  }, [dispatch, data, lang]);

  // useEffect(() => {
  //   if (lang) {
  //     trigger(lang);
  //   }
  // }, [trigger, lang]);

  // console.log(1);
  // console.log('isLoading', isLoading);
  // console.log('lang', lang);
  // console.log('dictionary', dictionary);

  // if (isLoading || !lang || !dictionary) return null;

  if (error) return <ErrorPage code={error} />;

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="limits" element={<Limits />} />
        <Route path="software-development" element={<SoftwareDevelopment />} />
        <Route path="terms">
          <Route index element={<Error />} />
          <Route path=":slug" element={<Terms />} />
        </Route>
        <Route
          path="privacy-policy"
          element={<Navigate to="/terms/terms-and-conditions" replace />}
        />
        <Route path="careers" element={<Careers />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="prices" element={<Prices />} />
        <Route path="questions" element={<Questions />}>
          <Route path=":category" element={<QuestionsSection />} />
        </Route>
        <Route path="affiliate" element={<Affiliate />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}

export default App;
