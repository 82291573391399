import { FunctionComponent, useEffect, useState } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { TextGrid } from '../../components/layout/textGrid';
import { MarkdownParser } from '../../components/layout/textBlock';
import { Container } from '../../components/layout/container';
import { Main } from '../../components/layout/main';
import { Loader } from '../../components/common/loader';
import data from '../../translations/dictionary.json';

export const SoftwareDevelopment: FunctionComponent = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  const pageData = {
    'subtitle': 'Software development',
    'title': 'Products and Services',
    'titleContent': ' '
  };

  useEffect(() => {
    const loadContent = async () => {
      try {
        const filePath = '/content/terms/products-and-services.html';
        const response = await fetch(filePath);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const htmlContent = await response.text();
        setContent(htmlContent);
      } catch (error) {
        console.error('Error loading content:', error);
      } finally {
        setLoading(false);
      }
    };
    console.log('useEffect');
    loadContent();
  }, []);

  if (loading) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  return (
    <Main>
      <PageHeader
        subtitle={pageData['subtitle']}
        title={pageData['title']}
      >
      </PageHeader>
      <Container>
        <TextGrid>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </TextGrid>
      </Container>
    </Main>
  );
};