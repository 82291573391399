import { FunctionComponent, useEffect, useState } from 'react';
import { Container } from '../container';
import styles from './footer.module.css';
import { FooterMenu } from '../footerMenu';
import { Translation } from '../../../translations/translation';
import { FooterMenuLink } from '../footerMenu/footerMenuLink';
import { FooterLogos } from '../footerLogos';
import { ConfigType } from 'store/api/config/types';
import { useConfigData } from 'hooks/useConfigData';

export const Footer: FunctionComponent = () => {
  const { data } = useConfigData();

  const [section, setSection] = useState<ConfigType | undefined>(undefined);

  useEffect(() => {
    if (data) {
      const obj: ConfigType = {
        ...data,
        footerSections: {
          ...data.footerSections,
          'footer:section:column:fifth': [
            {
              key: 'header:section:sign_in',
              path: 'https://client.blocknot.com',
              target: '_self',
            },
            {
              key: 'header:section:sign_up',
              path: 'https://client.blocknot.com/registration',
              target: '_self',
            },
          ],
          'footer:section:column:first': [
            ...data.footerSections['footer:section:column:first']
            // .filter(
            //   (el, i) => i === 0
            // ),
          ],
        },
      };

      setSection(obj);
    }
  }, [data]);

  if (!section) return null;
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footer__menuRow}>
          {Object.keys(section.footerSections).map((item) => (
            <FooterMenu title={<Translation k={item} />} key={item}>
              {section.footerSections[item].map((link, index) => {
                if (link.path.includes(':') && link.path[0] !== '/') {
                  return (
                    <FooterMenuLink
                      key={index}
                      href={link.path}
                      target={link.target}
                      rel={
                        link.target === '_blank' ? 'noopener noreferrer' : ''
                      }
                    >
                      <Translation k={link.key} />
                    </FooterMenuLink>
                  );
                }
                return (
                  <FooterMenuLink to={link.path} key={index}>
                    <Translation k={link.key} />
                  </FooterMenuLink>
                );
              })}
            </FooterMenu>
          ))}
        </div>
        <FooterLogos />
        <div className={styles.footer__copyright}>
          {section.footerNotes.map((note) => {
            return (
              <p key={note}>
                <Translation k={note} />
              </p>
            );
          })}
        </div>
      </Container>
    </footer>
  );
};
